.group-2-single-line > div:first-child {
    width: 34%;
}

.group-2-single-line > div:last-child {
    width: 33%;
}

.group-2-single-line > div:last-child label {
    display: none !important;
}

.group-3-single-line > div:first-child {
    width: 30%;
}

.group-3-single-line > div:last-child {
    width: 35%;
}

.group-3-single-line > div:last-child {
    display: flex !important;
}

.group-3-single-line > div:last-child label{
    display: none !important;
}

.group-3-single-line > div:last-child > div:first-child{
    width: 50%;
}

.group-3-single-line > div:last-child > div:last-child{
    width: 50%;
}
