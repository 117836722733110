//@import '../../../../styles/common.scss';

.summary-title {
    width: 100%;
    padding: 18px 24px 0;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 16px;
}

.summary-body {
    /* height: calc(100vh - 205px); */
    max-height: calc(100vh - 175px);
    overflow-y: auto;
}

.has-top-buttons .summary-body {
    max-height: calc(100vh - 246px);
    overflow-y: auto;
}

.summary-table.container {
    padding: 12px 0;
    margin: 0 24px;
    max-width: calc(100% - 48px);
    border-bottom: 1px solid #e0e0e0;

    &:last-of-type {
        border: 0;
    }

    > .collapsing {

        > .summary-row {
            // border-top: 1px solid #e0e0e0;
            align-items: center;
            margin: 0;

            &:last-of-type {

                .summary-table-first-col,
                .edit-button {
                    padding-bottom: 0;
                }
        
                .summary-table-content-col {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.summary-table.container > .summary-row {

    &.row {
        margin: 0;
        align-items: center;

        .col,
        .col-1,
        .col-2,
        .col-3,
        .col-4,
        .col-5,
        .col-6,
        .col-7,
        .col-8,
        .col-9,
        .col-10,
        .col-11,
        .col-12,
        .col,
        .col-auto,
        .col-sm-1,
        .col-sm-2,
        .col-sm-3,
        .col-sm-4,
        .col-sm-5,
        .col-sm-6,
        .col-sm-7,
        .col-sm-8,
        .col-sm-9,
        .col-sm-10,
        .col-sm-11,
        .col-sm-12,
        .col-sm,
        .col-sm-auto,
        .col-md-1,
        .col-md-2,
        .col-md-3,
        .col-md-4,
        .col-md-5,
        .col-md-6,
        .col-md-7,
        .col-md-8,
        .col-md-9,
        .col-md-10,
        .col-md-11,
        .col-md-12,
        .col-md,
        .col-md-auto,
        .col-lg-1,
        .col-lg-2,
        .col-lg-3,
        .col-lg-4,
        .col-lg-5,
        .col-lg-6,
        .col-lg-7,
        .col-lg-8,
        .col-lg-9,
        .col-lg-10,
        .col-lg-11,
        .col-lg-12,
        .col-lg,
        .col-lg-auto,
        .col-xl-1,
        .col-xl-2,
        .col-xl-3,
        .col-xl-4,
        .col-xl-5,
        .col-xl-6,
        .col-xl-7,
        .col-xl-8,
        .col-xl-9,
        .col-xl-10,
        .col-xl-11,
        .col-xl-12,
        .col-xl,
        .col-xl-auto {
            padding-right: 0;
            padding-left: 0;
        }

        .col .summary-row.row {
            margin: 0;

            .summary-table-content-col {
                border-left: 1px solid #e0e0e0;
                padding-left: 16px;
            }
        }
    }
}

.summary-table-first-col {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 52px;

    .col-3 {
        display: flex;
        padding-left: 0;
        padding-right: 8px;
        font-size: 12px;

        b {
            text-transform: uppercase;
            color: #7f868c;
            align-self: center;
        }
    }

    .col-9 {
        padding-left: 0;
        font-size: 14px;
        line-height: 1.25em;
    }
}

.summary-table-content-col {
    border-left: 1px solid #e0e0e0;
    margin-top: 12px;
    margin-bottom: 12px;
    padding-left: 16px;
    padding-right: 24px;
    font-size: 12px;
    line-height: 22px;
    min-height: 22px;

    span {
        margin-right: 16px;
        color: #6d757b;

        b {
            color: #2b3136;
        }

        span:last-of-type {
            margin-right: 0;
        }
    }
}

.edit-button {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button.btn-sm {
        min-width: 80px;
    }
}

.go-button a.btn {
    min-width: 80px;
}

.collapse-title {
    /* border-top: 1px solid #efeff0; */
    padding: 0 0 8px 52px;

    .stage-collapse-title {
        color: #0ba4e8;
        font-size: 14px;
        cursor: pointer;
    }
}

.arrow {
    width: 10px;
    height: 1rem;
    position: relative;
    top: 1px;
    margin-left: 4px;
    display: inline-block;
    cursor: pointer;

    span {
        top: .6rem;
        position: absolute;
        width: 6px;
        height: .1rem;
        background-color: #030303;
        display: inline-block;
        transition: all .2s ease;

        &:first-of-type {
            left: 0;
            transform: rotate(45deg);
        }

        &:last-of-type {
            right: 0;
            transform: rotate(-45deg);
        }
    }

    &.active {
        span {
            &:first-of-type {
                transform: rotate(-45deg);
            }

            &:last-of-type {
                transform: rotate(45deg);
            }
        }
    }
}

.numberCircle {
    font-size: 16px;
    display: block;
    float: left;
    box-sizing: initial;
    /* border: .18em solid #bababa; */
    color: #6d757b;
    font-weight: bold;
    text-align: center;
    letter-spacing: normal;
    border-radius: 50%;
    line-height: 40px;
    box-sizing: content-box;
    width: 40px;
    height: 40px;
    background-color: #e7e9ea;
}

.stageTitle {
    font-size: 16px;
    padding: 8px 16px 10px 52px;
    line-height: 24px;
    cursor: pointer;
}

.stageLink {
    font-size: 14px;
    text-decoration: underline;
    margin-top: 4px;
}

.link {
    font-size: 14px;
    margin-top: 4px;
}

.rowLink {
    color: #0ba4e8 !important;
    text-decoration: underline !important;
}