@import '../../../../styles/variables';

ol.progtrckr {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
    /* width: 100%; */
    position: absolute;
    padding-top: 16px;
    padding-left: 8px;
}

ol.progtrckr li {
    display: inline-block;
    text-align: center;
    /* line-height: 4.5rem; */
    padding-bottom: 4px;
}

ol.progtrckr li span {
    padding: 0 1.25rem;
    border-left: 1px solid #dadcde;
}

ol.progtrckr li:first-of-type span {
    border-left: 0;
}

@media (max-width: 650px) {
    .progtrckr li span {
        display: none;
    }
}

.progtrckr em {
    display: none;
    font-weight: 700;
    padding-left: 1rem;
}

@media (max-width: 650px) {
    .progtrckr em {
        display: inline;
    }
}

ol.progtrckr li.progtrckr-todo {
    color: #7f868c;

    span {
        cursor: pointer;

        &:hover {
            color: #0a9cdd;
        }
    }
}

ol.progtrckr li.progtrckr-doing {
    color: #0a9cdd;
    border-bottom: 2px solid #0a9cdd;
    font-weight: 500;
}

ol.progtrckr li.progtrckr-done {
    color: #070808;
    /* border-bottom: 4px solid #a4a9ad; */
}

/*
ol.progtrckr li:after {
    content: "\00a0\00a0";
}

ol.progtrckr li:before {
    position: relative;
    bottom: -3.7rem;
    float: left;
    left: 50%;
}

ol.progtrckr li.progtrckr-todo:before {
    content: "\039F";
    color: silver;
    background-color: white;
    width: 1.2em;
    line-height: 1.4em;
}

ol.progtrckr li.progtrckr-todo:hover:before {
    color: #ff4500;
}

ol.progtrckr li.progtrckr-doing:before {
    content: '\2022';
    color: white;
    background-color: #ccc;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-doing:hover:before {
    color: #ff4500;
}

ol.progtrckr li.progtrckr-done:before {
    content: '\2713';
    color: white;
    background-color: #a4a9ad;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
}

ol.progtrckr li.progtrckr-done:hover:before {
    color: #333;
}
*/

.workflow-stage {
    height: calc(100vh - 50px);   
    max-height: calc(100vh - 50px);
    overflow: hidden;

    &.d-flex.flex-column.border.border-light {
        border: 0 !important;
    }

    > .form-group {
        overflow-y: auto;
        margin-bottom: 0;
    }
}
.test-instructions-container {
    padding-left: 30px;
    width: 55%;
}

.test-instructions-container {
    padding-left: 30px;
    width: 55%;
}

.test-instructions-title.summary-title {
    height: 75px;
    padding-left: 10px;
}

.test-instructions {
    font-size: 18px;
}

.test-instruction-desc {
    padding-left: 60px;
}

.iconCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: normal;
    border-radius: 50%;
    width: 140px;
    height: 140px;
    background-color: #e7e9ea;
}

.notes {
    display: flex;
    flex-wrap:wrap;
}

