@import '../../styles/variables';

$sidebar-nav-dropdown-indicator-hover:      url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2373818f' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");

#pan-nav {

    &.app-header {
        border: 0;
    }
}

.left-nav.sidebar {
    height: calc(100vh - 50px);
}

.logs {
    background-image: url(/images/treemenu/logs.png);
}

.network {
     background-image: url(/images/treemenu/network.png);
}

.saas {
    background-image: url(/images/treemenu/saas.png);
}

.addresses {
    background-image: url(/images/treemenu/addresses.png);
}

.address-groups {
     background-image: url(/images/treemenu/address-groups.png);
}

.regions {
    background-image: url(/images/treemenu/regions.png);
}

.applications {
    background-image: url(/images/treemenu/applications.png);
}

.application-groups {
    background-image: url(/images/treemenu/application-groups.png);
}

.application-filter {
    background-image: url(/images/treemenu/application-filter.png);
}

.services {
    background-image: url(/images/treemenu/services.png);
}

.service-groups {
    background-image: url(/images/treemenu/service-groups.png);
}

.tags {
    background-image: url(/images/treemenu/tags.png);
}

.hip-objects {
    background-image: url(/images/treemenu/hip-objects.png);
}

.hip-profiles {
    background-image: url(/images/treemenu/hip-profiles.png);
}

.edl {
    background-image: url(/images/treemenu/external-dynamic-lists.png);
}

.profiles {
    background-image: url(/images/treemenu/profiles.png);
}

.vulnerability-protection {
    background-image: url(/images/treemenu/vulnerability-protection.png);
}

.file-blocking {
    background-image: url(/images/treemenu/file-blocking.png);
}

.url-filtering {
    background-image: url(/images/treemenu/url-filtering.png);
}

.wildfire-analysis {
    background-image: url(/images/treemenu/wildfire-analysis.png);
}

.dos-protection {
    background-image: url(/images/treemenu/dos-protection.png);
}

.security-profile-groups {
    background-image: url(/images/treemenu/security-profile-groups.png);
}

.decryption-profile {
    background-image: url(/images/treemenu/decryption-profile.png);
}

.security-profiles {
    background-image: url(/images/treemenu/security-profiles.png);
}

.antivirus {
    background-image: url(/images/treemenu/antivirus.png);
}

.anti-spyware {
    background-image: url(/images/treemenu/anti-spyware.png);
}

.url-category {
    background-image: url(/images/treemenu/url-category.png);
}

.data-filtering {
    background-image: url(/images/treemenu/data-filtering.png);
}

.inbound-security {
    background-image: url(/images/treemenu/inbound-security.png);
}

.outbound-security {
    background-image: url(/images/treemenu/outbound-security.png);
}

.qos {
    background-image: url(/images/treemenu/qos.png);
}

.decryption {
    background-image: url(/images/treemenu/decryption.png);
}

.app-override {
    background-image: url(/images/treemenu/app-override.png);
}

.authentication {
    background-image: url(/images/treemenu/authentication.png);
}

.remote-networks {
    background-image: url(/images/treemenu/remote-networks.png);
}

.mobile-users {
    background-image: url(/images/treemenu/mobile-users.png);
}

.service-connections {
    background-image: url(/images/treemenu/service-connections.png);
}

.sidebar {

    .nav-link.nav-dropdown-toggle {

        &::before {
            transform: rotate(-180deg);
            width: 10px;
            height: 10px;
        }
  
        &:hover,
        &.disabled:hover {

            &::before {
                background-image: $sidebar-nav-dropdown-indicator-hover;
            }
        }
    }
}