.jumpto-sc-viewer {
    text-align: center;

    .go-to-sc-btn {
        margin: 20px auto;
    }

    .scCircle {
        font-size: 16px;
        display: block;
        float: left;
        box-sizing: initial;
        /* border: .18em solid #bababa; */
        font-weight: bold;
        text-align: center;
        letter-spacing: normal;
        border-radius: 50%;
        line-height: 40px;
        box-sizing: content-box;
        width: 269px;
        height: 269px;
        background-color: rgba(224, 224, 224, 0.25);
        margin-bottom: 16px;

        img {
            margin: 62px 0px 59px 0px;
        }
    }
}
