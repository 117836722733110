
.x-pan-application {
    width: 16px;
    height: 16px;
    background-image: url(/images/appbrowser/application.png);
    background-repeat: no-repeat;
}

// .x-pan-appfilter {
//     background-image: url(../images/application_filter.png);
//     background-position: center center;
//     background-repeat: no-repeat;
// }

// .x-pan-appgroup {
//     background-image: url(../images/application_group.png);
//     background-position: center center;
//     background-repeat: no-repeat;
// }

.x-pan-appfunction {
    width: 16px;
    height: 16px;
    background-image: url(/images/appbrowser/app_function.png);
    background-repeat: no-repeat;
}

.x-pan-customapp {
    width: 16px;
    height: 16px;
    background-image: url(/images/appbrowser/custom_app.png);
    background-repeat: no-repeat;
}

.x-tree-elbow {
    width: 16px;
    height: 16px;
    background-position: 0 -5px;
    background-image: url(/images/appbrowser/elbow.gif);
    background-repeat: no-repeat;
}

.x-tree-elbow-end {
    width: 16px;
    height: 16px;
    background-image: url(/images/appbrowser/elbow-end.gif);
    background-repeat: no-repeat;
}

.app-browser {
    padding-bottom: 15px;
}

.app-browser-grid .pan-ag-grid {
    height: calc(100vh - 165px - 230px);   
    max-height: calc(100vh - 165px - 230px);
    width: calc(100vw - 200px);
    border: 0;
}


