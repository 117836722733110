.ServiceInfraTableHeader {
    background-color: #f6f7f7;
    color: #49535b;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: .05rem;

    + .row {
        margin: 0;

        > .col {
            padding-left: 0;
            padding-right: 0;

            .notification-container {
                border-bottom: 1px solid #dadcde;
                height: 134px;
            }

            &:first-of-type .notification-container {
                border-right: 1px solid #dadcde;
            }
        }

        .viewer-display.form-group.col {
            padding: 15px;
            border: 1px solid #dadcde;
            border-width: 0 1px 1px 0;

            .form-group {
                margin: 0;

                .col-3.col {
                    display: flex;
                    padding-left: 0;
                }
            }

            + .col {
                padding: 0;
            }
        }
    }
}

.ServiceInfraTableHeaderInner {
    border-right: 0;
    border-top: 0; 
    border-bottom: 1px solid #dadcde;

    > div {
        padding-left: 25px;
        padding-top: 11px;
        padding-bottom: 11px;
    }
}

.notification-row {
    width: 100%;
    padding: 15px;
}

.notification-col {
    padding-left: 0;
    padding-right: 0;

    > .notification-label-col {
        margin: 0 26px;
        width: 125px;
    }

    div {
        display: inline-block;
    }
}

.label-word {
    font-size: 12px;
    color: #7f868c;
    text-transform: uppercase;
    letter-spacing: .6px;
    font-weight: 800;
    line-height: 1.3;
    align-self: start;
    padding-top: 10px;
    text-align: right;

    + div {
        padding-left: 0;
        padding-right: 0;

        &.col-1 {
            padding-right: 8px;
            min-width: 60px;
        }
    }
}

.egress-api-input-group {
    display: flex !important;
    flex-direction: row;

    > input {
        border-color: #c8cbce !important;
    }

    .input-group-text {
        height: 100%;
        background-color: #ffffff;
        color: #1d2124;
        font-size: 10px;
        cursor: pointer;
    }
}

.egress-api-btn {
    width: 102px;
}

.service-infra-viewer {

    background-color: #fff;

    .card::before {
        height: 0;
        background: none;
    }

    .h-100 {
        margin-bottom: 20px;
    }
}