$remoteNetworkColor:'#2e637c';
$allocatedServiceConnectionColor:'#8cb669';
$unallocatedColor:'#d3d3d3';

.sc-add-checking-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .foot-container {
        display: flex;

        .box {
            margin: 0 2px;
            width: 16px;
            height: 16px;
            border: 1px solid #fff;

            +div {
                line-height: 16px
            }
        }
    }
}