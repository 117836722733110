
.form-group [for='$.stage1.deployment.region.entry'].col-form-label {
    padding-top: 6px;
    align-self: auto;

    + div.col > div {
        margin-top: 3px;
        margin-bottom: 8px;

        u {
            margin-right: 4px;
            cursor: pointer;
        }
    }
}

[for='$.stage1.custom-domain.ssl-tls-service-profile'].col-form-label,
[for='$.stage2.saml-certificate'].col-form-label,
[for='$.stage2.saml-idp-profile'].col-form-label,
[for='$.stage4.SAML-Prisma-SaaS.saml-idp-profile'].col-form-label {
    padding-top: 6px;
    align-self: auto;
}

[id='$.config.devices.entry.plugins.cloud_services.adelphi_gpcs_workflow.mobile-users.onboarding.entry'] .hlgrid-form-wide {
    max-width: 1180px;
    min-width: 980px;

    .col-form-label {
        width: 190px;
        max-width: 190px;
    }
}
.muConfigStatusHolder {
    display: flex;
    align-items: center;
}
.muConfigStatusIcon {
    padding: 5px;
}