#explore-logviewer {
	button.log-query-btn {
		color: black;
		border: 1px solid #c8cbce;
		height: 32px;
		width: 32px;
		line-height: 16px;
		vertical-align: middle;
		background-color: #ffffff;
		min-width: 16px;

		&:disabled {
			background-color: #f5f5f5;
			opacity: 1;
		}

		&:last-of-type {
			width: 35px;
		}
		&:hover {
			background-color: transparent;
		}
		&.btn {
			&:focus {
				outline: none !important;
				box-shadow: none !important;
			}
		}
	}
}
