.scope h2 {
	margin-top: 16px;
	margin-bottom: 8px;
}

.scope:first-child h2 {
	margin-top: 0;
}

.commit-desc {
	margin: 16px -15px 0;
}