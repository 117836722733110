.ag-theme-balham .ag-root {
    border-bottom: 0;
}

.ag-theme-balham {
    color: #414a51;
}

.ag-theme-balham .ag-ltr .ag-cell-focus {
    border-color: #009ace;
}

#settings-panel {
    background-color: #fff;
    z-index: 2000;
    border-left: 1px solid #ccc;
    box-shadow: 0 0 4px rgba(0, 0, 0, .1);
}

#icon-settings {
    opacity: .3;
    cursor: pointer;
    width: 20px;
    height: 20px;
}

#icon-settings:hover {
    opacity: .6;
}

.toast-bottom-left {
    left: 240px !important;
}

.blah {
    background-color: #eaeaea;
    display: inline-block;
    height: 18px;
    margin-bottom: 6px;
    width: 100%;
}

#legend-table td {
    padding: 4px;
    border-top: none;
    font-size: 14px;

}

.circle {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    float: left;
    margin-top: 6px;
    margin-right: 8px;
}

.circle.public {
    background-color: #d9534f;
}

.circle.external {
    background-color: #f0ad4e;
}

.circle.company {
    background-color: #888;
}

.circle.internal {
    background-color: #b2b2b2;
}

span.severity {
    color: #fff;
    font-size: 13px;
    padding: 1px 6px;
    margin-right: 5px;
}

span.severity-5 {
    background-color: #bc043b;
}

span.severity-4 {
    background-color: #fd8434;
}

#incidents-table .ellipsize {
    width: 150px;
}

.ballicon {
    width: 34px;
}

.valign-middle td {
    vertical-align: middle;
}

.note {
    position: fixed;
    bottom: 10px;
    right: 10px;
    padding: 20px;
    background-color: bisque;
    width: 400px;
    font-size: 12px;
    z-index: 10000;
}

.bar-section {
    float: left;
}

.bar {
    background-color: #ccc;
    border: 0 none;
    display: inline-block;
    float: none;
    height: 6px;
    margin-left: 8px;
    margin-right: 8px;
    position: relative;
    top: -2px;
    width: 50px;
    border-radius: 4px;
    overflow: hidden;
    height: 8px;
}

.progress-bar-assets {
    background-color: #009ace;
    color: #009ace;
    width: 100%;
    height: 8px;
}

.card-inverse {
    background-color: #ccc;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #ccc;
    position: relative;
    left: 265px;
}

.card-inverse .row {
    padding-left: 25px;
    padding-right: 25px;
}

.card-inverse .card {
    margin-right: 12px;
    flex-grow: 1;
}

.card-inverse .card:last-child {
    margin-right: 0;
}

.card-inverse .image-area {
    height: 100px;
    line-height: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
}

.dot-success {
    width: 15px;
    height: 15px;
    margin-bottom: -2px;
    background-color: #00babc;
    display: inline-block;
    border-radius: 20px;
    margin-right: 5px;
}

.hamburger-icon-container {
    z-index: 4;
    position: relative;
    top: 9px;
    left: 9px;
    width: 30px;
    height: 30px;
}

.line1 {
    top: 4px;
    transition: 0.15s cubic-bezier(.32, .98, 0.71, 1);
    display: block;
    width: 20px;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 7px;
    z-index: 1;
}

.line2 {
    top: 11px;
    transition: 0.15s cubic-bezier(.32, .98, 0.71, 1);
    display: block;
    width: 20px;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 7px;
    z-index: 1;
    opacity: 1;
}

.line3 {
    top: 18px;
    transition: 0.15s cubic-bezier(0.32, 0.98, 0.71, 1);
    display: block;
    width: 20px;
    height: 1px;
    background: #fff;
    position: absolute;
    left: 7px;
    z-index: 1;
}

.menu-open .line1 {
    transform: rotate(45deg);
    transform-origin: 0 0;
}

.menu-open .line2 {
    opacity: 0;

}

.menu-open .line3 {
    transform: rotate(-45deg);
    transform-origin: 0 100%;
}

#app-switcher-menu {
    position: fixed;
    right: 0 !important;
    width: 400px;
    background-color: #fff;
    padding: 0;
    height: 100%;
    z-index: 10000;
    top: 0 !important;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
    display: none;
}

.item {
    display: flex;
    align-items: center;
    height: 30px;
}

#apps-list1 .item {
    display: flex;
    align-items: center;
    height: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
}

#apps-list1 .item:hover {
    background-color: #e6f5fa;
    cursor: pointer;
}

.icon-col {
    width: 60px;
    text-align: center;
}

.caret-col {
    width: 30px;
    text-align: center;
}

.chevron {
    transition: .2s ease-in-out;
}

.rotate {
    transform: rotate(90deg);
}

.list-of-resources a {
    display: block;
    padding: 5px 15px;
}

#account-area {
    padding-left: 15px;
}

.list-of-resources .item {
    height: 35px;
}

.list-of-resources .icon-col {
    width: 30px;
    margin-left: 15px;
}

#close-switcher-menu {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 18px;
    opacity: .5;
    cursor: pointer;
}

#close-switcher-menu:hover {
    opacity: .8;
}

.grid-item {
    text-align: center;
    width: 100px;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
}

.app_wrapper {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.grid-item .app-name {
    font-size: 12px;
    width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#app-switcher-menu.slidein {
    animation: slidein .25s forwards;
}

@keyframes slidein {
    0% {
        right: -420px;
    }

    100% {
        right: 0;
    }
}

#app-switcher-menu.slideout {
    animation: slideout .25s forwards;
}

@keyframes slideout {
    0% {
        right: 0;
    }

    100% {
        right: -420px;
    }
}

.app-name {
    font-weight: 600;
}

.instance-name {
    font-size: 12px;
    color: #999;
}

.utility-container {
    margin-right: 5rem !important;
    z-index: 1;
}

#pan-nav .utility-container .dropdown .dropdown-menu {
    top: 0 !important;
}

.navbar-brand.brand-container {
    font-weight: bold;
    color: #fff;
    font-size: 14px;
}

#pan-nav {
    /* Universal styles for links and icons on the nav bar */
    .menu-link {
        background-color: transparent;
        border-color: transparent;
        position: relative;
        height: 50px;
        /* line-height: 50px; */
        outline: 0;
        padding: 0;
        text-transform: uppercase;
        color: #a4a9ad;

        a {
            color: #a4a9ad;
            -webkit-transition: all .25s ease;
            -moz-transition: all .25s ease;
            -ms-transition: all .25s ease;
            transition: all .25s ease;
            padding: 15px 20px;
            font-size: 12px;
            font-weight: 800;
            text-transform: uppercase;
            letter-spacing: .75px;
            height: 50px;
            display: block;
            cursor: pointer;
            outline: 0;
    
            &:hover {
                color: #fff !important;
                text-decoration: none;
            }
    
            &.active {
                color: #fff;
                text-decoration: none;
            }
        }

        &:disabled {
            cursor: not-allowed;
        }
    }
}