.test-instructions-title.summary-title {
    height: 75px;
    padding-left: 10px;
}

.test-instructions {
    font-size: 18px;
}

.test-instruction-desc {
    padding-left: 60px;
}

.pic {
    background-image: url(/images/mu/thumbsUp.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; 
    width: 71px;
    height: 68px;
}

.gearIcon {
    background-image: url(/images/mu/gear.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; 
    width: 71px;
    height: 71px;
}

.iconCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: normal;
    border-radius: 50%;
    width: 140px;
    height: 140px;
    background-color: #e7e9ea;
}

.notes {
    display: flex;
    flex-wrap: wrap;
}

.details {
    max-width: 80%;
}
