@import "./styles/Constants.scss";

$drawer-prefix-cls: "pan-drawer";

.#{$drawer-prefix-cls} {
	position: fixed;
	z-index: $zindex-modal;
	width: 0%;
	height: 100%;
	transition: transform $animation-duration-slow $ease-base-out, height 0s ease $animation-duration-slow, width 0s ease $animation-duration-slow;
	> * {
		transition: transform $animation-duration-slow $ease-base-out, box-shadow $animation-duration-slow $ease-base-out;
	}

	&-content-wrapper {
		position: absolute;
	}
	.#{$drawer-prefix-cls}-content {
		width: 100%;
		height: 100%;
	}

	&-left,
	&-right {
		top: 0;
		width: 0%;
		height: 100%;
		.#{$drawer-prefix-cls}-content-wrapper {
			height: 100%;
		}
		&.#{$drawer-prefix-cls}-open {
			width: 100%;
			transition: transform $animation-duration-slow $ease-base-out;
		}
		&.#{$drawer-prefix-cls}-open.no-mask {
			width: 0%;
		}
	}

	&-left {
		&.#{$drawer-prefix-cls}-open {
			.#{$drawer-prefix-cls}-content-wrapper {
				box-shadow: $shadow-1-right;
			}
		}
	}

	&-right {
		right: 0;

		.#{$drawer-prefix-cls} {
			&-content-wrapper {
				right: 0;
			}
		}
		&.#{$drawer-prefix-cls}-open {
			.#{$drawer-prefix-cls}-content-wrapper {
				box-shadow: $shadow-1-left;
			}
			&.no-mask {
				right: 1px;
				transform: translateX(1px);
			}
		}
	}

	&-top,
	&-bottom {
		left: 0;
		width: 100%;
		height: 0%;

		.#{$drawer-prefix-cls}-content-wrapper {
			width: 100%;
		}
		&.#{$drawer-prefix-cls}-open {
			height: 100%;
			transition: transform $animation-duration-slow $ease-base-out;
		}
		&.#{$drawer-prefix-cls}-open.no-mask {
			height: 0%;
		}
	}

	&-top {
		top: 0;

		&.#{$drawer-prefix-cls}-open {
			.#{$drawer-prefix-cls}-content-wrapper {
				box-shadow: $shadow-1-down;
			}
		}
	}

	&-bottom {
		bottom: 0;

		.#{$drawer-prefix-cls} {
			&-content-wrapper {
				bottom: 0;
			}
		}
		&.#{$drawer-prefix-cls}-open {
			.#{$drawer-prefix-cls}-content-wrapper {
				box-shadow: $shadow-1-up;
			}
			&.no-mask {
				bottom: 1px;
				transform: translateY(1px);
			}
		}
	}

	&.#{$drawer-prefix-cls}-open {
		.#{$drawer-prefix-cls} {
			&-mask {
				height: 100%;
				opacity: 1;
				transition: none;
				animation: drawerFadeIn $animation-duration-slow $ease-base-out;
			}
		}
	}

	&-title {
		margin: 0;
		color: $heading-color;
		font-weight: 500;
		font-size: $font-size-lg;
		line-height: 22px;
	}

	&-content {
		position: relative;
		z-index: 1;
		overflow: auto;
		background-color: $component-background;
		background-clip: padding-box;
		border: 0;
	}

	&-close {
		position: absolute;
		top: 0;
		right: 0;
		z-index: $zindex-popup-close;
		display: block;
		width: 56px;
		height: 56px;
		padding: 0;
		color: $text-color-secondary;
		font-weight: 700;
		font-size: $font-size-lg;
		font-style: normal;
		line-height: 56px;
		text-align: center;
		text-transform: none;
		text-decoration: none;
		background: transparent;
		border: 0;
		outline: 0;
		cursor: pointer;
		transition: color $animation-duration-slow;
		text-rendering: auto;

		&:focus,
		&:hover {
			color: $icon-color-hover;
			text-decoration: none;
		}
	}

	&-header {
		position: relative;
		padding: $drawer-header-padding;
		color: $text-color;
		background: $component-background;
		border-bottom: $border-width-base $border-style-base $border-color-split;
		border-radius: $border-radius-base $border-radius-base 0 0;
	}

	&-header-no-title {
		color: $text-color;
		background: $component-background;
	}

	&-body {
		padding: $drawer-body-padding;
		font-size: $font-size-base;
		line-height: $line-height-base;
		word-wrap: break-word;
	}
	&-wrapper-body {
		height: 100%;
		overflow: auto;
	}

	&-mask {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 0;
		background-color: $modal-mask-bg;
		opacity: 0;
		filter: alpha(opacity=45);
		transition: opacity $animation-duration-slow linear, height 0s ease $animation-duration-slow;
	}
	&-open {
		&-content {
			box-shadow: $shadow-2;
		}
	}
}
