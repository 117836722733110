.icon-combo-color1,
.icon-combo-color1 em,
.icon-combo-color1 span,
.icon-combo-color2,
.icon-combo-color2 em,
.icon-combo-color2 span,
.icon-combo-color3,
.icon-combo-color3 em,
.icon-combo-color3 span,
.icon-combo-color5,
.icon-combo-color5 em,
.icon-combo-color5 span,
.icon-combo-color6,
.icon-combo-color6 em,
.icon-combo-color6 span,
.icon-combo-color7,
.icon-combo-color7 em,
.icon-combo-color7 span,
.icon-combo-color8,
.icon-combo-color8 em,
.icon-combo-color8 span,
.icon-combo-color12,
.icon-combo-color12 em,
.icon-combo-color12 span,
.icon-combo-color13,
.icon-combo-color13 em,
.icon-combo-color13 span,
.icon-combo-color14,
.icon-combo-color14 em,
.icon-combo-color14 span,
.icon-combo-color15,
.icon-combo-color15 em,
.icon-combo-color15 span,
.icon-combo-color16,
.icon-combo-color16 em,
.icon-combo-color16 span,
.icon-combo-color17,
.icon-combo-color17 em,
.icon-combo-color17 span,
.icon-combo-color19,
.icon-combo-color19 em,
.icon-combo-color19 span,
.icon-combo-color20,
.icon-combo-color20 em,
.icon-combo-color20 span,
.icon-combo-color22,
.icon-combo-color22 em,
.icon-combo-color22 span,
.icon-combo-color24,
.icon-combo-color24 em,
.icon-combo-color24 span,
.icon-combo-color25,
.icon-combo-color25 em,
.icon-combo-color25 span,
.icon-combo-color26,
.icon-combo-color26 em,
.icon-combo-color26 span,
.icon-combo-color27,
.icon-combo-color27 em,
.icon-combo-color27 span,
.icon-combo-color28,
.icon-combo-color28 em,
.icon-combo-color28 span,
.icon-combo-color29,
.icon-combo-color29 em,
.icon-combo-color29 span,
.icon-combo-color30,
.icon-combo-color30 em,
.icon-combo-color30 span,
.icon-combo-color31,
.icon-combo-color31 em,
.icon-combo-color31 span,
.icon-combo-color32,
.icon-combo-color32 em,
.icon-combo-color32 span,
.icon-combo-color38,
.icon-combo-color38 em,
.icon-combo-color38 span,
.icon-combo-color39,
.icon-combo-color39 em,
.icon-combo-color39 span,
.icon-combo-color40,
.icon-combo-color40 em,
.icon-combo-color40 span,
.icon-combo-color41,
.icon-combo-color41 em,
.icon-combo-color41 span,
.icon-combo-color42,
.icon-combo-color42 em,
.icon-combo-color42 span {
    color: #FFFFFF !important;
}

.icon-combo-color4,
.icon-combo-color4 em,
.icon-combo-color4 span,
.icon-combo-color9,
.icon-combo-color9 em,
.icon-combo-color9 span,
.icon-combo-color10,
.icon-combo-color10 em,
.icon-combo-color10 span,
.icon-combo-color11,
.icon-combo-color11 em,
.icon-combo-color11 span,
.icon-combo-color21,
.icon-combo-color21 em,
.icon-combo-color21 span,
.icon-combo-color23,
.icon-combo-color23 em,
.icon-combo-color23 span,
.icon-combo-color33,
.icon-combo-color33 em,
.icon-combo-color33 span,
.icon-combo-color34,
.icon-combo-color34 em,
.icon-combo-color34 span,
.icon-combo-color35,
.icon-combo-color35 em,
.icon-combo-color35 span,
.icon-combo-color36,
.icon-combo-color36 em,
.icon-combo-color36 span,
.icon-combo-color37,
.icon-combo-color37 em,
.icon-combo-color37 span {
    color: #000000 !important;
}

.icon-combo-color0 {
    /*default white color for background*/
    background-color: white !important;
}

.icon-combo-color1 {
    /*red*/
    background-color: #CD383F !important;
}

.icon-combo-color2 {
    /*green*/
    background-color: #72A392 !important;
}

.icon-combo-color3 {
    /*blue*/
    background-color: #6ba2b9 !important;
}

.icon-combo-color4 {
    /*yellow*/
    background-color: #EBD722 !important;
}

.icon-combo-color5 {
    /*pink -> Copper*/
    background-color: #C27D2A !important;
}

.icon-combo-color6 {
    /*orange*/
    background-color: #f6851f !important;
}

.icon-combo-color7 {
    /*purple*/
    background-color: #7D3953 !important;
}

.icon-combo-color8 {
    /*gray*/
    background-color: #5B6F7B !important;
}

.icon-combo-color9 {
    /*light green*/
    background-color: #C9D6A6 !important;
}

.icon-combo-color10 {
    /*cyan*/
    background-color: #8AD3DF !important;
}

.icon-combo-color11 {
    /*violet -> Light Gray*/
    background-color: #C2D1D3 !important;
}

.icon-combo-color12 {
    /*dark blue -> Blue Gray*/
    background-color: #80A1B6 !important;
}

.icon-combo-color13 {
    /*lime*/
    background-color: #a7c439 !important;
}

.icon-combo-color14 {
    /*black*/
    background-color: #000000 !important;
}

.icon-combo-color15 {
    /*gold*/
    background-color: #FFC425 !important;
}

.icon-combo-color16 {
    /*indigo -> Brown*/
    background-color: #918A75 !important;
}

.icon-combo-color17 {
    /*Palo Alto Networks green */
    background-color: #97A822 !important;
}

.icon-combo-color18 {
    /*white */
    background-color: #FFFFFF !important;
    border: 1px solid #bcbcc0;
}

.icon-combo-color19 {
    /*Palo Alto Networks maroon */
    background-color: #C32148 !important;
}

.icon-combo-color20 {
    /*Palo Alto Networks red-orange */
    background-color: #FF681F !important;
}

.icon-combo-color21 {
    /*Palo Alto Networks yellow-orange */
    background-color: #FFAE42 !important;
}

.icon-combo-color22 {
    /*Palo Alto Networks forest green */
    background-color: #5FA777 !important;
}

.icon-combo-color23 {
    /*Palo Alto Networks turquoise blue */
    background-color: #6CDAE7 !important;
}

.icon-combo-color24 {
    /*Palo Alto Networks azure blue */
    background-color: #4997D0 !important;
}

.icon-combo-color25 {
    /*Palo Alto Networks cerulean blue */
    background-color: #339ACC !important;
}

.icon-combo-color26 {
    /*Palo Alto Networks midnight blue */
    background-color: #00468C !important;
}

.icon-combo-color27 {
    /*Palo Alto Networks medium blue */
    background-color: #4570E6 !important;
}

.icon-combo-color28 {
    /*Palo Alto Networks cobalt blue */
    background-color: #8C90C8 !important;
}

.icon-combo-color29 {
    /*Palo Alto Networks violet blue */
    background-color: #766EC8 !important;
}

.icon-combo-color30 {
    /*Palo Alto Networks blue violet */
    background-color: #6456B7 !important;
}

.icon-combo-color31 {
    /*Palo Alto Networks medium violet */
    background-color: #8F47B3 !important;
}

.icon-combo-color32 {
    /*Palo Alto Networks medium rose */
    background-color: #D96CBE !important;
}

.icon-combo-color33 {
    /*Palo Alto Networks lavender */
    background-color: #BF8FCC !important;
}

.icon-combo-color34 {
    /*Palo Alto Networks orchid */
    background-color: #E29CD2 !important;
}

.icon-combo-color35 {
    /*Palo Alto Networks thistle */
    background-color: #EBB0D7 !important;
}

.icon-combo-color36 {
    /*Palo Alto Networks peach */
    background-color: #FFCBA4 !important;
}

.icon-combo-color37 {
    /*Palo Alto Networks salmon */
    background-color: #FF91A4 !important;
}

.icon-combo-color38 {
    /*Palo Alto Networks magenta */
    background-color: #F653A6 !important;
}

.icon-combo-color39 {
    /*Palo Alto Networks red violet */
    background-color: #BB3385 !important;
}

.icon-combo-color40 {
    /*Palo Alto Networks mahogany */
    background-color: #CA3435 !important;
}

.icon-combo-color41 {
    /*Palo Alto Networks burnt sienna */
    background-color: #AB6744 !important;
}

.icon-combo-color42 {
    /*Palo Alto Networks chestnut */
    background-color: #B94E48 !important;
}