.bgp-status-peerblock{
    .title{
        cursor: pointer;
        display: inline-block;
        padding: 0.5em;
    }
}

.bgp-status-interval {
    display: flex;
    flex-direction: row-reverse;
    .select{
        width:15em;
    }
    span{
        padding-right: 0.5em;
    }

}