.generate-button {
    margin-bottom: 10px;
}

.copy-icon {
    float: right;
    padding-right: 10px;
    cursor: pointer;;
}

.table-header h2 {
	margin-top: 16px;
	margin-bottom: 8px;
}

.case-id-label {
    font-size: 12px;
    color: #7f868c;
    text-transform: uppercase;
    letter-spacing: .6px;
    font-weight: 800;
    float: right;
    padding-top: 4px;
}

.help-text {
    margin-bottom: 10px;
}