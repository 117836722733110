.pan-time-range-picker {
	@import "style/Dropdown";
	.time-range-picker {
		cursor: pointer;
		position: relative;

		&.pan-input {
			width: auto;
		}

		input.pan-calendar-picker-input {
			height: 20px;
			border: none;
			cursor: pointer;
			margin-left: 20px;
			margin-top: 1px;
			background-color: transparent;
		}

		// TODO: fix in cloud-base
		.pan-dropdown-menu-item-disabled.pan-dropdown-menu-item.pan-menu-item:hover {
			color: rgba(0, 0, 0, 0.25);
		}

		.time-picker-calendar-icon {
			position: absolute;
			width: 16px;
			height: 16px;
			left: 10px;
			top: 50%;
			margin-top: -8px;
			line-height: 16px;
			user-select: none;
			background-image: url(../../../../images/icon-calendar-dark.svg);
			opacity: 0.6;
		}

		&.time-range-picker-active,
		&:hover {
			.time-picker-calendar-icon {
				opacity: 1;
			}
		}

		.date-range-picker {
			pointer-events: none;
			opacity: 0;
			position: absolute;
			top: 40px;
			right: 0;
			height: 0;
		}

		&.time-picker-preset-custom > .pan-calendar-picker-input {
			width: 350px;
		}

		&.time-range-picker-active {
			border-color: #31b0d4;
			outline: 0;
			box-shadow: 0 0 0 2px rgba(14, 154, 200, 0.2);
		}
	}

	.date-range-picker-popup .pan-calendar .pan-calendar-ok-btn {
		color: #fff !important;
		background: linear-gradient(#10ace0, #0c88b0);
		border-color: #0c88b0;
		display: inline;

		&:hover,
		&:focus {
			color: #fff !important;
			background: linear-gradient(#11b7ee, #0d93be) !important;
			border-color: #0e9ac8;
		}

		&:focus {
			box-shadow: none;
		}

		&:active {
			color: #fff;
			background: #0b7598;
			border-color: #0a6c8c;
			box-shadow: none;
		}

		&.pan-calendar-ok-btn-disabled,
		&.pan-calendar-ok-btn-disabled:hover {
			color: #999;
			opacity: 0.8;
			background: #efefef;
			border-color: #d6d5d5;
			box-shadow: none;
			cursor: not-allowed;
		}
	}
}
