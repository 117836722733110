ol.progtrckr + .wizard-step.card {
    margin-top: 48px;
}

.wizard-step.card {
    border: 0;
    padding: 18px 16px 0 24px;

    &::before {
        height: 0;
    }
    
    .card-body {
        padding: 0;
    
        .card-title {
            margin-bottom: 0;
        
            h1 {
                width: 450px;
                font-weight: 400;
                font-size: 26px;
                line-height: 26px;
                text-align: left;
                white-space: nowrap;
                vertical-align: middle;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                color: #0e1012;
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                padding-left: 0 !important;
                margin-right: 20px;
            }
        }

        .card-text {
            color: #6d757b;
            margin-left: 52px;
            font-size: 12px;
            line-height: 16px;
        }

        .pagination-bar {
            padding: 5px 16px;
        }

        .fieldset:first-child:last-child,
        .fieldset:last-child {
            margin-bottom: 8px;
        }
    }
}

.action-buttons {
    padding: 24px 0 16px;
    /* position: absolute; */
    /* bottom: 0; */
    border-top: 1px solid #dadada;
    margin: 0 24px;
}
