
.app-filter-margin{
    margin-top: -45px;
    display: inline-block;
    position: absolute;
    right: 20px;
}

.filter-border{
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
}

.pan-ag-grid.app-browser{
    height: calc(100vh - 461px);
}