
.filter-input {
    height: 210px;
    padding-left: 0;
    padding-right: 0;

    .card {
        height: 100%;
        border-radius: 0;
        border-right: 0;
        margin: 0;
    }

    .card::before {
        height: 0;
    }

    .card-header {
        padding: 10px 8px;
        /* text-transform: uppercase; */
        /* font-weight: 600; */
        font-size: 16px;
        background: #f6f7f7;
        border-bottom: 1px solid #c8cbce;
        color: #49535b;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 11px;
        letter-spacing: .05rem;
    }

    .card-header + .card-body {
        padding: 0;
    }

    a:not([href]):not([tabindex]).list-group-item {
        border: none;
        padding: 1px 0;
        font-size: 12px;
        color: #1d2124;
    }
}

.filter-count {
    display: inline-block;
    min-width: 44px;
    text-align: right;
    margin-right: 8px;
    color: #7f868c;
}

.filter-selected {
    font-weight: bold;
    color: rgba(0, 0, 0, .8);
}

.filter-input:first-of-type {
    .card {
        border-left: 0;
    }

    .card-header {
        padding-left: 24px;
    }

    .filter-count {
        padding-left: 8px;
    }
}
