.info-window {
    display: inline-block;
    padding: 10px;
    width: 530px;
    height: 300px;
    border: 1px solid #d3d5d8;
    border-radius: 4px; 
    overflow-y: auto;
    vertical-align: top;
    margin-top: 10px;
}

.data {
    font-family: "Lato", sans-serif;
    font-size: 11px;
    white-space: nowrap;
}

.exportCert {
    position: absolute;
    left: 20px;
}