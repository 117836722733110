.action-icons{
  .fa{
    cursor: pointer;
    display: inline-block;
    margin: 0 15px 0 0;
    line-height: 24px;
    color: #7f868c;
    font-size: 15px;
  }
}
.preview-icon-content{
  img{
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}