#explore-logviewer {
	.log-query-wrapper {
		display: flex;

		.pan-select-selection,
		.pan-input {
			border-color: #c8cbce;
		}

		.pan-select-dropdown {
			z-index: 1150;
		}

		.pan-input-group.pan-input-group-compact {
			flex: 1 0;
			display: flex;
			// background-color: #fff;

			&.log-query-input-group {
				margin-right: 15px;

				&::after {
					content: '';
					border-left: 1px solid #979797;
					width: 1px;
					height: 32px;
					margin-left: 15px;
				}
			}

			&.log-query-date-group {
				flex: 0;
			}

			> * {
				flex: none;
				// fix border and effect overlapping issue
				&.pan-select-focused,
				&:focus,
				&:active,
				&:hover {
					z-index: 1100;
				}
			}

			> .log-query-completion,
			> .log-query-input {
				flex: 1 0;
			}

			// fix border moving issue
			> .log-query-logtype-select {
				> .pan-select-selection {
					border-right-width: 1px !important;
					margin-right: -1px;
					min-width: 100px;
				}
			}

			> *:first-child,
			> .pan-select:first-child > .pan-select-selection,
			> .pan-calendar-picker:first-child .pan-input,
			> .pan-select-auto-complete:first-child .pan-input,
			> .pan-cascader-picker:first-child .pan-input,
			> .pan-mention-wrapper:first-child .pan-mention-editor,
			> .pan-time-picker:first-child .pan-time-picker-input,
			> .pan-time-range-picker:first-child .pan-input {
				border-top-left-radius: 32px !important;
				border-bottom-left-radius: 32px !important;
			}

			> *:last-child,
			> .pan-select:last-child > .pan-select-selection,
			> .pan-calendar-picker:last-child .pan-input,
			> .pan-select-auto-complete:last-child .pan-input,
			> .pan-cascader-picker:last-child .pan-input,
			> .pan-mention-wrapper:last-child .pan-mention-editor,
			> .pan-time-picker:last-child .pan-time-picker-input,
			> .pan-time-range-picker:first-child .pan-input {
				border-top-right-radius: 32px !important;
				border-bottom-right-radius: 32px !important;
			}
		}
	}
}
