#explore-logviewer {
	.log-query-completion {
		.log-query-icon-green {
			color: #3f9b6f;
		}

		.log-query-icon-red {
			color: #d9534f;
		}

		.log-query-icon-yellow {
			color: #d2b55b;
		}

		&.pan-select-auto-complete.pan-select .pan-select-selection__placeholder {
			margin-left: 30px;
		}

		.pan-select-search--inline {
			height: 32px; // temp-fix
		}

		.pan-btn.pan-input-search-button {
			border-radius: 0;
			height: 32px;
			line-height: 14px;
		}

		.log-query-icon {
			cursor: text;
		}

		.pan-input {
			border-color: #c8cbce;
		}

		input.pan-input:focus:hover {
			padding-right: 24px;
		}
	}

	.log-query-completion-dropdown {
		.log-query-option-desc {
			float: right;
			text-transform: capitalize;
			font-style: italic;
		}

		.pan-select-dropdown-menu-item.pan-select-dropdown-menu-item-active {
			background-color: rgba(0, 0, 0, 0.05);
		}
	}

	.log-query-btn-wrap {
		position: relative;
		width: 25px;
		height: 30px;
		margin: 1px 1px 1px -26px;
		z-index: 1100;
		background: #fff;
		transition: opacity 0.1s;
		text-align: right;
		opacity: 0;

		&:hover {
			opacity: 1;
		}
	}

	.log-query-completion:hover + .log-query-btn-wrap {
		opacity: 0.7;
	}

	.log-query-completion.log-query-empty + .log-query-btn-wrap {
		opacity: 0;
		pointer-events: none;
		user-select: none;
	}

	button.log-query-btn.log-query-btn-clear {
		margin: 7px 6px 7px 0;
		border: 0px transparent solid;
		width: 16px;
		height: 16px;
		border-radius: 16px;
		padding: 0;
		background-image: url(../../../../../node_modules/ui-lib/src/images/icon-close-circle.svg);
	}

	.action-cmp-border-radius {
		border-radius: 18px;
	}
	.border-radius {
		border-radius: 5rem;
	}
	.pan-input {
		font-family: 'Lato', sans-serif;
		font-variant: tabular-nums;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		list-style: none;
		position: relative;
		display: inline-block;
		padding: 4px 11px;
		width: 100%;
		height: 32px;
		font-size: 14px;
		line-height: 1.2;
		color: #333;
		background-color: #fff;
		background-image: none;
		border: 1px solid #d9d9d9;
		border-radius: 4px;
		transition: all 0.3s;
	}
	.pan-input::-moz-placeholder {
		color: #bfbfbf;
		opacity: 1;
	}
	.pan-input:-ms-input-placeholder {
		color: #bfbfbf;
	}
	.pan-input::-webkit-input-placeholder {
		color: #bfbfbf;
	}
	.pan-input:hover {
		border-color: #31b0d4;
		border-right-width: 1px !important;
	}
	.pan-input:focus {
		border-color: #31b0d4;
		outline: 0;
		box-shadow: 0 0 0 2px rgba(14, 154, 200, 0.2);
		border-right-width: 1px !important;
	}
	.pan-input-disabled {
		background-color: #f5f5f5;
		opacity: 1;
		cursor: not-allowed;
		color: rgba(0, 0, 0, 0.25);
	}
	.pan-input-disabled:hover {
		border-color: #e6d8d8;
		border-right-width: 1px !important;
	}
	textarea.pan-input {
		max-width: 100%;
		height: auto;
		vertical-align: bottom;
		transition: all 0.3s, height 0s;
		min-height: 32px;
	}
	.pan-input-lg {
		padding: 6px 11px;
		height: 40px;
		font-size: 16px;
	}
	.pan-input-sm {
		padding: 1px 7px;
		height: 24px;
	}
	.pan-input-group {
		font-family: 'Lato', sans-serif;
		font-size: 14px;
		font-variant: tabular-nums;
		line-height: 1.2;
		color: #333;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		list-style: none;
		position: relative;
		display: table;
		border-collapse: separate;
		border-spacing: 0;
		width: 100%;
	}
	.pan-input-group[class*='col-'] {
		float: none;
		padding-left: 0;
		padding-right: 0;
	}
	.pan-input-group > [class*='col-'] {
		padding-right: 8px;
	}
	.pan-input-group > [class*='col-']:last-child {
		padding-right: 0;
	}
	.pan-input-group-addon,
	.pan-input-group-wrap,
	.pan-input-group > .pan-input {
		display: table-cell;
	}
	.pan-input-group-addon:not(:first-child):not(:last-child),
	.pan-input-group-wrap:not(:first-child):not(:last-child),
	.pan-input-group > .pan-input:not(:first-child):not(:last-child) {
		border-radius: 0;
	}
	.pan-input-group-addon,
	.pan-input-group-wrap {
		width: 1px;
		white-space: nowrap;
		vertical-align: middle;
	}
	.pan-input-group-wrap > * {
		display: block !important;
	}
	.pan-input-group .pan-input {
		float: left;
		width: 100%;
		margin-bottom: 0;
	}
	.pan-input-group .pan-input:focus {
		z-index: 1;
		border-right-width: 1px;
	}
	.pan-input-group .pan-input:hover {
		z-index: 1;
		border-right-width: 1px;
	}
	.pan-input-group-addon {
		padding: 0 11px;
		font-size: 14px;
		font-weight: normal;
		line-height: 1;
		color: #333;
		text-align: center;
		background-color: #fafafa;
		border: 1px solid #d9d9d9;
		border-radius: 4px;
		position: relative;
		transition: all 0.3s;
	}
	.pan-input-group-addon .pan-select {
		margin: -5px -11px;
	}
	.pan-input-group-addon .pan-select .pan-select-selection {
		background-color: inherit;
		margin: -1px;
		border: 1px solid transparent;
		box-shadow: none;
	}
	.pan-input-group-addon .pan-select-open .pan-select-selection,
	.pan-input-group-addon .pan-select-focused .pan-select-selection {
		color: #0e9ac8;
	}
	.pan-input-group-addon > i:only-child:after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.pan-input-group > .pan-input:first-child,
	.pan-input-group-addon:first-child {
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
	}
	.pan-input-group > .pan-input:first-child .pan-select .pan-select-selection,
	.pan-input-group-addon:first-child .pan-select .pan-select-selection {
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
	}
	.pan-input-group > .pan-input-affix-wrapper:not(:first-child) .pan-input {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
	}
	.pan-input-group > .pan-input-affix-wrapper:not(:last-child) .pan-input {
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
	}
	.pan-input-group-addon:first-child {
		border-right: 0;
	}
	.pan-input-group-addon:last-child {
		border-left: 0;
	}
	.pan-input-group > .pan-input:last-child,
	.pan-input-group-addon:last-child {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
	}
	.pan-input-group > .pan-input:last-child .pan-select .pan-select-selection,
	.pan-input-group-addon:last-child .pan-select .pan-select-selection {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
	}
	.pan-input-group-lg .pan-input,
	.pan-input-group-lg > .pan-input-group-addon {
		padding: 6px 11px;
		height: 40px;
		font-size: 16px;
	}
	.pan-input-group-sm .pan-input,
	.pan-input-group-sm > .pan-input-group-addon {
		padding: 1px 7px;
		height: 24px;
	}
	.pan-input-group-lg .pan-select-selection--single {
		height: 40px;
	}
	.pan-input-group-sm .pan-select-selection--single {
		height: 24px;
	}
	.pan-input-group .pan-input-affix-wrapper {
		display: table-cell;
		width: 100%;
		float: left;
	}
	.pan-input-group.pan-input-group-compact {
		display: block;
		zoom: 1;
	}
	.pan-input-group.pan-input-group-compact:before,
	.pan-input-group.pan-input-group-compact:after {
		content: '';
		display: table;
	}
	.pan-input-group.pan-input-group-compact:after {
		clear: both;
	}
	.pan-input-group.pan-input-group-compact > * {
		border-radius: 0;
		border-right-width: 0;
		vertical-align: top;
		float: none;
		display: inline-block;
	}
	.pan-input-group.pan-input-group-compact > span > .pan-input {
		border-right-width: 0;
	}
	.pan-input-group.pan-input-group-compact .pan-input {
		float: none;
	}
	.pan-input-group.pan-input-group-compact > .pan-select > .pan-select-selection,
	.pan-input-group.pan-input-group-compact > .pan-calendar-picker .pan-input,
	.pan-input-group.pan-input-group-compact > .pan-select-auto-complete .pan-input,
	.pan-input-group.pan-input-group-compact > .pan-cascader-picker .pan-input,
	.pan-input-group.pan-input-group-compact > .pan-mention-wrapper .pan-mention-editor,
	.pan-input-group.pan-input-group-compact > .pan-time-picker .pan-time-picker-input {
		border-radius: 0;
		border-right-width: 0;
	}
	.pan-input-group.pan-input-group-compact > *:first-child,
	.pan-input-group.pan-input-group-compact > .pan-select:first-child > .pan-select-selection,
	.pan-input-group.pan-input-group-compact > .pan-calendar-picker:first-child .pan-input,
	.pan-input-group.pan-input-group-compact > .pan-select-auto-complete:first-child .pan-input,
	.pan-input-group.pan-input-group-compact > .pan-cascader-picker:first-child .pan-input,
	.pan-input-group.pan-input-group-compact > .pan-mention-wrapper:first-child .pan-mention-editor,
	.pan-input-group.pan-input-group-compact > .pan-time-picker:first-child .pan-time-picker-input {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}
	.pan-input-group.pan-input-group-compact > *:last-child,
	.pan-input-group.pan-input-group-compact > .pan-select:last-child > .pan-select-selection,
	.pan-input-group.pan-input-group-compact > .pan-calendar-picker:last-child .pan-input,
	.pan-input-group.pan-input-group-compact > .pan-select-auto-complete:last-child .pan-input,
	.pan-input-group.pan-input-group-compact > .pan-cascader-picker:last-child .pan-input,
	.pan-input-group.pan-input-group-compact > .pan-mention-wrapper:last-child .pan-mention-editor,
	.pan-input-group.pan-input-group-compact > .pan-time-picker:last-child .pan-time-picker-input {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		border-right-width: 1px;
	}
	.pan-input-group-wrapper {
		display: inline-block;
		vertical-align: top;
		width: 100%;
	}
	.pan-input-affix-wrapper {
		font-family: 'Lato', sans-serif;
		font-size: 14px;
		font-variant: tabular-nums;
		line-height: 1.2;
		color: #333;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		list-style: none;
		position: relative;
		display: inline-block;
		width: 100%;
	}
	.pan-input-affix-wrapper:hover .pan-input:not(.pan-input-disabled) {
		border-color: #31b0d4;
		border-right-width: 1px !important;
	}
	.pan-input-affix-wrapper .pan-input {
		position: static;
	}
	.pan-input-affix-wrapper .pan-input-prefix,
	.pan-input-affix-wrapper .pan-input-suffix {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		line-height: 0;
		color: #333;
	}
	.pan-input-affix-wrapper .pan-input-prefix :not(.panicon),
	.pan-input-affix-wrapper .pan-input-suffix :not(.panicon) {
		line-height: 1.2;
	}
	.pan-input-affix-wrapper .pan-input-prefix {
		left: 12px;
	}
	.pan-input-affix-wrapper .pan-input-suffix {
		right: 12px;
	}
	.pan-input-affix-wrapper .pan-input:not(:first-child) {
		padding-left: 30px;
	}
	.pan-input-affix-wrapper .pan-input:not(:last-child) {
		padding-right: 30px;
	}
	.pan-input-affix-wrapper .pan-input {
		min-height: 100%;
	}
	.pan-input-search-icon {
		color: rgba(0, 0, 0, 0.45);
		cursor: pointer;
		transition: all 0.3s;
	}
	.pan-input-search-icon:hover {
		color: #333;
	}
	.pan-input-search:not(.pan-input-search-small) > .pan-input-suffix {
		right: 12px;
	}
	.pan-input-search > .pan-input-suffix > .pan-input-search-button {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
	.pan-input-search > .pan-input-suffix > .pan-input-search-button > .panicon-search {
		font-size: 16px;
	}
	.pan-input-search.pan-input-search-enter-button > .pan-input {
		padding-right: 46px;
	}
	.pan-input-search.pan-input-search-enter-button > .pan-input-suffix {
		right: 0;
	}
	.pan-form-item label {
		color: #7f868c;
	}
	.pan-form-item-required:before {
		display: inline-block;
		margin-right: 4px;
		content: '\25CF';
		font-family: 'Lato', sans-serif;
		line-height: 1;
		font-size: 14px;
		color: #d9534f;
	}
	.pan-form-hide-required-mark .pan-form-item-required:before {
		display: none;
	}
	.has-error .pan-form-explain,
	.has-error .pan-form-split {
		color: #d9534f;
	}
	.has-error .pan-input,
	.has-error .pan-input:hover {
		border-color: #d9534f;
	}
	.has-error .pan-input:focus {
		border-color: #e67f77;
		outline: 0;
		box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.2);
		border-right-width: 1px !important;
	}
	.has-error .pan-input:not([disabled]):hover {
		border-color: #d9534f;
	}
	.has-error .pan-calendar-picker-open .pan-calendar-picker-input {
		border-color: #e67f77;
		outline: 0;
		box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.2);
		border-right-width: 1px !important;
	}
	.has-error .pan-input-prefix {
		color: #d9534f;
	}
	.has-error .pan-input-group-addon {
		color: #d9534f;
		border-color: #d9534f;
		background-color: #fff;
	}
	.has-error .has-feedback {
		color: #d9534f;
	}
	.has-error .pan-input:focus {
		box-shadow: none;
	}
}
